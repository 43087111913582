import axios from "axios";

import dayjs from "dayjs";

import { DATE_FORMAT } from "../constants";

export const inscriptionPost = (data) => {
  let inscription = data;

  inscription.birthdate = dayjs
    .utc(inscription.birthdate, DATE_FORMAT)
    .toISOString();

  let tutors = (inscription.legalTutors || []).map((tutor) => {
    tutor.birthdate = dayjs.utc(tutor.birthdate, DATE_FORMAT).toISOString();

    return tutor;
  });

  if (tutors.length > 0) {
    inscription.legalTutors = tutors;
  } else {
    delete inscription.legalTutors;
  }

  return axios.post("/api/inscriptions", inscription);
};
