import { FormattedMessage } from "react-intl";

import styled from "@emotion/styled";

import FormLabel from "@mui/material/FormLabel";

function PersonalDataConditions({ rgpdUrl, copyrightUrl }) {
  return (
    <StyledDiv>
      <StyledLabel>
        <a href={rgpdUrl}>
          <FormattedMessage id="rgpd" />
        </a>
        &
        <a href={copyrightUrl}>
          <FormattedMessage id="copyright" />
        </a>
      </StyledLabel>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  text-align: center;
  margin: 24px 0;
`;

const StyledLabel = styled(FormLabel)`
  color: #575757;
`;

export default PersonalDataConditions;
