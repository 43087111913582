export const fr = {
  form_title: "Formulaire d'inscription",
  form_years: "2024 - 2025",
  form_disable: "",
  person_detail: "Elève",
  person_firstname: "Prénom",
  student_firstname: "Prénom de l'élève",
  person_lastname: "Nom",
  student_lastname: "Nom de l'élève",
  person_email: "E-mail",
  student_birthdate: "Date de naissance de l'élève",
  person_birthdate: "Date de naissance",
  phone_prefix: "Préfix",
  phone_number: "Téléphone",
  discipline: "Discipline(s)",
  discipline_add: "Ajouter une discipline",
  discipline_disciplines: "Disciplines",
  discipline_courses: "Cours",
  discipline_schedule: "Horaire",
  discipline_schedule_add: "Ajouter un horaire",
  discipline_schedule_occurence: "min. {occurence} occurences/semaine",
  discipline_remove: "Supprimer la discipline",
  on_demand: "Merci de prendre directement contact avec l'école pour ce cours.",
  to_confirm: "Horaires à venir.",
  register: "s'inscrire",
  registrtation: "Inscription élève",
  registration_error:
    "Votre demande d'inscription n'a pas été soumise. Une erreur technique est survenue.{br}{br}Veuillez recommencer plus tard.",
  registration_error_close: "Fermer",
  registration_success: "Votre demande d'inscription a été soumise avec succès.",
  important: "IMPORTANT:",
  check_junk: "Si vous ne recevez pas de confirmation par e-mail, merci de vérifier vos courriers indésirables. ",
  no_mail_contact_us: "Si vous n'avez pas reçu de mail après 1h, merci de nous contacter par e-mail.",
  responsible: "Contact(s) supplémentaire(s)",
  responsible_info:
    "Vous avez la possibilité d'ajouter un ou plusieurs contacts supplémentaires.",
  responsible_add: "Ajouter un contact",
  responsible_remove: "Supprimer le responsable",
  address: "Adresse",
  address_number: "Numéro",
  address_street: "Rue",
  address_postal_code: "Code Postal",
  address_city: "Ville",
  address_country: "Pays",
  generals_conditions_title: "Conditions générales",
  general_conditions_text:
    "J'ai lu et accepté les {generals_conditions_url} de vente ainsi que le {internal_regulations_url}.",
  generals_conditions: "conditions générales",
  internal_regulations: "règlement d'ordre intérieur",
  rgpd: "RGPD & Traitement des données personnelles ",
  copyright: " Droit à l'image",
  form_error_title: "Erreur",
  form_error_minor:
    "L'inscrit est mineur, un responsable doit être renseigné !",
  must_be_adult: "Le contact supplémentaire doit être majeur pour continuer.",
  form_info_contact_title: "Ceci est un formulaire d inscription",
  form_info_contact: "Pour les demandes de renseignements merci de compléter plutôt le formulaire de contact : ",
  form_info_contact_link: "Formulaire de contact",
  form_info_details: "Si vous rencontrez des soucis ou vous avez un doute sur quel cours/niveau réserver pour votre enfant, les inscriptions sont possibles également par courriel à ",
  form_info_mail: "info@sophiegobert.com",
  error_required_schedule: "Sélectionné un horaire",
  form_info_yoga_title: "Yoga",
  form_info_yoga: "Les inscriptions aux cours de Yoga sont disponibles sur cette page: ",
  form_info_yoga_link: "Cours à l'unité",
  form_not_available: "Le formulaire d'inscription n'est pas disponible pour le moment.",
  info_mail_will_be_sent: "Une fois le formulaire correctement complété et validé vous recevrez un mail de confirmation de soumission. Veuillez vérifier vos spams. Si vous n'avez rien reçu c'est que votre envoi a échoué.",
  form_invalid_alert_title: "Attention : Formulaire non valide",
  form_invalid_alert: "Veuillez corriger les champs marqués en rouge, puis soumettre à nouveau le formulaire.",
  error_title: "Quelque chose ne s'est pas bien passé.",
  error_text_1: "Merci de nous contacter via le fomulaire de contact : ",
  error_text_2: "Vous pouvez aussi nous contacter directement par mail : "
};
