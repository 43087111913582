import {useEffect, useCallback, useState} from "react";
import {useFieldArray, useFormContext} from "react-hook-form";

import {FormattedMessage} from "react-intl";

import styled from "@emotion/styled";

import Button from "@mui/material/Button";
import ScheduleForm from "./ScheduleForm";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

function ScheduleFormArray({namePrefix, minOccurrence, schedules}) {
    const {control, getValues} = useFormContext();
    const {fields, remove, append} = useFieldArray({
        control,
        name: namePrefix,
    });

    //disableSchedule
    const [selectedSchedulesIds, setSelectedSchedulesIds] = useState([]);
    const [scheduleIdChanged, setScheduleIdChanged] = useState([]);
    useEffect(() => {
        const scheduleIds = getValues(namePrefix);
        setSelectedSchedulesIds(scheduleIds);
        setScheduleIdChanged(false);
    }, [scheduleIdChanged, schedules]);

    const onScheduleIdChange = () => {
        setScheduleIdChanged(true);
    }

    const onAppend = useCallback(() => {
        append({value: ""});
    }, [append]);

    useEffect(() => {
        // at least one field
        let count = minOccurrence || 1;

        // remove all previous generate fields
        remove();

        // add new fields
        for (let i = 0; i < count; ++i) {
            onAppend();
        }
    }, [remove, minOccurrence, onAppend]);

    const hasAtLeastOne = () => {
        return fields.length > 1;
    };

    const canRemove = () => {
        return fields.length >= minOccurrence + 1;
    };

    const hasOccurence = () => {
        return minOccurrence > 0;
    };

    const hasDeleteIcon = () => {
        return hasAtLeastOne() && canRemove();
    };

    const canAddSchedule = () => {
        return fields.length < schedules.length;
    };

    return (
        <>
            {fields.map((field, index) => (
                <Container key={field.id}>
                    <ContainerLeft>
                        <ScheduleForm
                            key={field.key}
                            namePrefix={`${namePrefix}[${index}]`}
                            schedules={schedules}
                            onScheduleIdchange={onScheduleIdChange}
                            selectedSchedulesIds={selectedSchedulesIds}
                        />
                    </ContainerLeft>

                    {hasDeleteIcon() && (
                        <ContainerRight>
                            <StyledIconButton
                                aria-label="delete"
                                color="error"
                                size="small"
                                onClick={() => {
                                    remove(index);
                                    onScheduleIdChange();
                                }}
                            >
                                <DeleteIcon fontSize="inherit"/>
                            </StyledIconButton>
                        </ContainerRight>
                    )}
                </Container>
            ))}

            {hasOccurence() && (
                <Info>
                    <FormattedMessage
                        id="discipline_schedule_occurence"
                        values={{occurence: minOccurrence}}
                    />
                </Info>
            )}

            <StyledButton
                disabled={!canAddSchedule()}
                fullWidth
                variant="outlined"
                size="small"
                onClick={onAppend}
            >
                <FormattedMessage id="discipline_schedule_add"/>
            </StyledButton>
        </>
    );
}

const Container = styled.div`
    display: flex;
`;

const ContainerLeft = styled.div`
    flex-grow: 1;
`;

const ContainerRight = styled.div`
    width: 40px;
    flex-grow: 0;
`;

const Info = styled.div`
    padding: 4px 0;
    color: #6c6c6c;
    text-align: right;
`;

const StyledButton = styled(Button)`
    margin-top: 16px;
`;

const StyledIconButton = styled(IconButton)`
    top: 20px;
    margin-left: 8px;
`;

export default ScheduleFormArray;
