import {useIntl} from "react-intl";
import {FormattedMessage} from "react-intl";

import styled from "@emotion/styled";

import {DateField} from "@mui/x-date-pickers/DateField";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import ConnectForm from "./ConnectForm";
import PhoneInput from "./PhoneInput";
import AddressFormArray from "./AddressFormArray";

import get from "lodash/get";

import {DATE_FORMAT} from "../constants";
import {useState} from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

function PersonForm({light, namePrefix, isTutor = false}) {
    const [showErrorAlert, setShowErrorAlter] = useState(false);
    const name = (name) => {
        return namePrefix ? namePrefix + name : name;
    };

    const intl = useIntl();

    const hasError = (errors, name) => {
        return !!get(errors, (namePrefix || "") + name);
    };

    const isMajor = (value) => {
        if (!value) {
            return false;
        }

        const birthdate = new Date(value);
        const today = new Date();
        let age = today.getFullYear() - birthdate.getFullYear();

        birthdate.setFullYear(today.getFullYear());
        if (today < birthdate) {
            age--;
        }

        if (age >= 18) {
            setShowErrorAlter(false);
            return true;
        } else {
            setShowErrorAlter(true);
            return false;
        }
    };

    return (
        <ConnectForm>
            {({register, formState: {errors}}) => (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                required
                                error={hasError(errors, "firstname")}
                                fullWidth
                                id={name("firstname")}
                                variant="outlined"
                                size="small"
                                label={intl.formatMessage({id: isTutor ? "person_firstname" : "student_firstname"})}
                                {...register(name("firstname"), {
                                    required: true,
                                    maxLength: 255,
                                })}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                required
                                error={hasError(errors, "lastname")}
                                fullWidth
                                id={name("lastname")}
                                variant="outlined"
                                size="small"
                                label={intl.formatMessage({id: isTutor ? "person_lastname" : "student_lastname"})}
                                {...register(name("lastname"), {
                                    required: true,
                                    maxLength: 255,
                                })}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <DateField
                                required
                                error={hasError(errors, "birthdate")}
                                fullWidth
                                variant="outlined"
                                size="small"
                                format={DATE_FORMAT}
                                label={intl.formatMessage({id: isTutor ? "person_birthdate" : "student_birthdate"})}
                                {...register(name("birthdate"), {
                                    required: true,
                                    validate: isTutor ? isMajor : undefined,
                                })}
                            />
                        </Grid>

                        {!light && (
                            <Grid item xs={6}>
                                <PhoneInput namePrefix={namePrefix}/>
                            </Grid>
                        )}

                        {!light && (
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    error={hasError(errors, "mail")}
                                    fullWidth
                                    id={name("mail")}
                                    variant="outlined"
                                    size="small"
                                    label={intl.formatMessage({id: "person_email"})}
                                    {...register(name("mail"), {
                                        required: true,
                                        pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                    })}
                                />
                            </Grid>
                        )}

                        {showErrorAlert && (
                            <Grid item xs={12}>
                                <Alert severity="error" variant="outlined">
                                    <AlertTitle>
                                        <FormattedMessage id="form_error_title"/>
                                    </AlertTitle>

                                    <FormattedMessage id="must_be_adult"/>
                                </Alert>
                            </Grid>
                        )}
                    </Grid>

                    <StyledTypography component="h4" variant="h6" align="left">
                        <FormattedMessage id="address"/>
                    </StyledTypography>

                    <Block>
                        <AddressFormArray namePrefix={name("addresses")}/>
                    </Block>
                </>
            )}
        </ConnectForm>
    );
}

// fix form overlapping due to the grid style
const Block = styled.div`
  div {
    margin-top: 0;
  }
`;

const StyledTypography = styled(Typography)`
  margin-top: 16px;
  color: #575757;
`;

export default PersonForm;
