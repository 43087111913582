import { FormattedMessage } from "react-intl";

import styled from "@emotion/styled";

import Typography from "@mui/material/Typography";

import stopwatch from "../assets/images/stopwatch.png";

function NotAvailable() {
    return (
        <Container>
            <div>
                <Image src={stopwatch} alt="stopwatch"></Image>

                <StyledTypography component="h1" variant="h4" align="center">
                    <FormattedMessage id="form_not_available" />
                </StyledTypography>
            </div>
        </Container>
    );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
`;

const Image = styled.img`
  padding: 24px;
  width: 100px;
  height: 100px;
  display: block;
  margin: auto;
`;

const StyledTypography = styled(Typography)`
  margin-top: 16px;
  color: #666;
`;

export default NotAvailable;
