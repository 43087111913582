import { useState, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import styled from "@emotion/styled";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import PersonForm from "./PersonForm";

function PersonFormArray({ namePrefix, min, max, isTutor = false }) {
  const { control } = useFormContext();

  const { fields, remove, append } = useFieldArray({
    control,
    name: namePrefix,
    shouldUnregister: true,
  });

  const [isAddDisabled, setIsAddDisabled] = useState(false);

  useEffect(() => {
    // remove previous fields
    remove();

    // add new fields
    for (let i = 0; i < min; ++i) {
      onAppend();
    }
  }, [remove, min]);

  useEffect(() => {
    setIsAddDisabled(fields.length >= max);
  }, [fields, max]);

  const onAppend = () => {
    if (!isAddDisabled) {
      append({
        firstname: "",
        lastname: "",
        mail: "",
        birthdate: "",
        phone: "",
      });
    }
  };

  return (
    <>
      {fields.map((field, index) => (
        <StyledCard key={index}>
          <StyledCardContent>
            <PersonForm
              key={field.key}
              namePrefix={`${namePrefix}[${index}].`}
              isTutor={isTutor}
            />
            <StyledButton
              fullWidth
              variant="outlined"
              size="small"
              onClick={() => remove(index)}
              color="error"
            >
              <FormattedMessage id="responsible_remove" />
            </StyledButton>
          </StyledCardContent>
        </StyledCard>
      ))}

      <SetRight>
        <StyledTextButton
          size="small"
          variant="outlined"
          disabled={isAddDisabled}
          onClick={onAppend}
        >
          <FormattedMessage id="responsible_add" />
        </StyledTextButton>
      </SetRight>
    </>
  );
}

const SetRight = styled.div`
  width: 100%;
  text-align: right;
  margin-bottom: 4em;
`;

const StyledButton = styled(Button)`
  margin-top: 16px;
`;

const StyledTextButton = styled(Button)``;

const StyledCard = styled(Card)`
  margin: 16px 0;
  background-color: #eee;
`;

const StyledCardContent = styled(CardContent)`
  padding: 16px !important;
`;

export default PersonFormArray;
