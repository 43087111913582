import axios from "axios";

export const getSchedules = (courseId) => {
  const params = new URLSearchParams();
  params.append("courseId", courseId);

  return axios
    .get(
      "/api/horaires/in-active-period?size=100&sort=dayOfWeek,asc&sort=start,asc",
      {
        params: params,
      }
    )
    .then((response) => {
      const schedules = response?.data?._embedded?.scheduleDtoList;
      if (schedules?.length) {
        return schedules;
      }

      return [];
    });
};

export const getCourses = (disciplineId) => {
    const params = new URLSearchParams();
    params.append("disciplineId", disciplineId);

  return axios
    .get("/api/courses/in-current-period?size=200&sort=dropDownOrder,asc",{params: params})
    .then((response) => {
      const courses = response?.data?._embedded?.courseDtoList;

      if (courses?.length) {
        return courses;
      }
      return [];
    });
};

export const getDisciplines = () => {
    return axios
        .get("/api/disciplines/in-current-period?size=200&sort=dropDownOrder,asc")
        .then((response) => {
            const disciplines = response?.data?._embedded?.disciplineDtoList;

            if (disciplines?.length) {
                return disciplines;
            }
            return [];
        });
};