import { useIntl } from "react-intl";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import ConnectForm from "./ConnectForm";

import get from "lodash/get";

function AddressForm({ namePrefix }) {
  const name = (name) => {
    return namePrefix ? namePrefix + name : name;
  };

  const intl = useIntl();

  const hasError = (errors, name) => {
    return !!get(errors, (namePrefix || "") + name);
  };

  return (
    <ConnectForm>
      {({ register, formState: { errors } }) => (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                error={hasError(errors, "country")}
                fullWidth
                id={name("country")}
                variant="outlined"
                size="small"
                label={intl.formatMessage({ id: "address_country" })}
                {...register(name("country"), {
                  required: true,
                  maxLength: 255,
                })}
              />
            </Grid>

            <Grid item xs={9}>
              <TextField
                required
                error={hasError(errors, "street")}
                fullWidth
                id={name("street")}
                variant="outlined"
                size="small"
                label={intl.formatMessage({ id: "address_street" })}
                {...register(name("street"), {
                  required: true,
                  maxLength: 255,
                })}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                required
                error={hasError(errors, "number")}
                fullWidth
                id={name("number")}
                variant="outlined"
                size="small"
                label={intl.formatMessage({ id: "address_number" })}
                {...register(name("number"), {
                  required: true,
                  maxLength: 255,
                })}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                required
                error={hasError(errors, "city")}
                fullWidth
                id={name("city")}
                variant="outlined"
                size="small"
                label={intl.formatMessage({ id: "address_city" })}
                {...register(name("city"), { required: true, maxLength: 255 })}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                required
                error={hasError(errors, "postalCode")}
                fullWidth
                id={name("postalCode")}
                variant="outlined"
                size="small"
                label={intl.formatMessage({ id: "address_postal_code" })}
                {...register(name("postalCode"), {
                  required: true,
                  maxLength: 255,
                })}
              />
            </Grid>
          </Grid>
        </>
      )}
    </ConnectForm>
  );
}

export default AddressForm;
