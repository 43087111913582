import { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import styled from "@emotion/styled";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CourseForm from "./CourseForm";

function DisciplineFormArray({ namePrefix }) {
  const { control, getValues } = useFormContext();

  const { fields, remove, append } = useFieldArray({
    control,
    name: namePrefix,
  });

  const [selectedCoursesIds, setSelectedCoursesIds] = useState([]);

  const [courseIdChanged, setCourseIdChanged] = useState([]);

  useEffect(() => {
    const coursesIds = getValues(namePrefix);
    const filteredCoursesIds = (coursesIds || []).map((item) => item.courseId);
    setSelectedCoursesIds(filteredCoursesIds);
    setCourseIdChanged(false);
  }, [courseIdChanged]);

  // add only one element even if it's render more than once
  useEffect(() => {
    remove(0);
    onAppend();
  }, [remove]);

  const onCourseIdchange = () => {
    setCourseIdChanged(true);
  };

  const onAppend = () => {
    append({ courseId: "", scheduleIds: [] });
  };

  const hasAtLeastOne = () => {
    return fields.length > 1;
  };

  return (
    <>
      {fields.map((item, index) => (
        <StyledCard key={item.id}>
          <StyledCardContent>
            <CourseForm
              namePrefix={`${namePrefix}[${index}].`}
              selectedCoursesIds={selectedCoursesIds}
              onCourseIdchange={onCourseIdchange}
            />
            {hasAtLeastOne() && (
              <StyledButton
                fullWidth
                variant="outlined"
                size="small"
                onClick={() => remove(index)}
                color="error"
              >
                <FormattedMessage id="discipline_remove" />
              </StyledButton>
            )}
          </StyledCardContent>
        </StyledCard>
      ))}

      <SetRight>
        <StyledTextButton size="small" onClick={onAppend} variant="outlined">
          <FormattedMessage id="discipline_add" />
        </StyledTextButton>
      </SetRight>
    </>
  );
}

const SetRight = styled.div`
  width: 100%;
  margin-bottom: 4em;
  text-align: right;
`;

const StyledButton = styled(Button)`
  margin-top: 16px;
`;

const StyledTextButton = styled(Button)``;

const StyledCard = styled(Card)`
  margin: 16px 0;
  background-color: #eee;
`;

const StyledCardContent = styled(CardContent)`
  padding: 16px !important;
`;

export default DisciplineFormArray;
