import { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import {FormattedMessage, useIntl} from "react-intl";
import { useNavigate } from "react-router-dom";

import styled from "@emotion/styled";

import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import PersonForm from "../components/PersonForm";
import PersonFormArray from "../components/PersonFormArray";
import DisciplineFormArray from "../components/DisciplineFormArray";
import GeneralsConditionsForm from "../components/GeneralsConditionsForm";
import PersonalDataConditions from "../components/PersonalDataConditions";
import ErrorModal from "../components/ErrorModal";

import { inscriptionPost } from "../services/inscription-api";

function Registration() {
  const methods = useForm();
  const { formState: { isSubmitted, isValid } } = methods;
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const generalsConditionsUrl =
    "https://www.sophiegobert.com/conditions-geacuteneacuterales.html";
  const internalRegulationsUrl = "https://www.sophiegobert.com/roi.html";
  const rgpdUrl = "https://www.sophiegobert.com/rgpd.html";
  const copyrightUrl = "https://www.sophiegobert.com/droit-agrave-limage.html";

  const onSubmit = (adultData) => {
    //Post inscription
    setLoading(true);
    inscriptionPost(adultData)
        .then((response) => {
          setData(response.data);
          navigate("/success");
        })
        .catch(() => {
          setShowErrorModal(true);
          navigate("/error");
        })
        .finally(() => {
          setLoading(false);
        });
  };

  const onCloseErrorModal = () => {
    setShowErrorModal(false);
  };

  const { formatMessage } = useIntl();
  const infoEmail = formatMessage({ id: 'form_info_mail' });
  const mailLink = `mailto:${infoEmail}`;

  return (
    <FormProvider {...methods}>
      <ErrorModal isOpen={showErrorModal} onClose={onCloseErrorModal} />

      <Container>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <StyledTitle component="h1" variant="h3" align="center">
            <FormattedMessage id="form_title" />
          </StyledTitle>
          <StyledTitle component="h1" variant="h3" align="center">
            <FormattedMessage id="form_years" />
          </StyledTitle>

          <StyledTitle component="h1" variant="h4" align="center">
            <FormattedMessage id="person_detail" />
          </StyledTitle>

          <Block>
            <PersonForm />
          </Block>

          <StyledTitle component="h1" variant="h4" align="center">
            <FormattedMessage id="responsible" />
          </StyledTitle>

          <StyledText align="center">
            <FormattedMessage id="responsible_info" />
          </StyledText>

          <PersonFormArray
            namePrefix={"legalTutors"}
            min={0}
            max={2}
            isTutor={true}
          />

          <StyledTitle
            component="h1"
            variant="h4"
            align="center"
            sx={{ marginBottom: "16px" }}
          >
            <FormattedMessage id="discipline" />
          </StyledTitle>

          <Block>
            <DisciplineFormArray namePrefix={"subscribedCourses"} />
          </Block>

          <Alert
            severity="info"
            variant="outlined"
            sx={{ marginBottom: "16px" }}
          >
            <AlertTitle>
              <FormattedMessage id="form_info_contact_title" />
            </AlertTitle>

                        <FormattedMessage id="form_info_contact"/>
                        <a href="https://www.sophiegobert.com/contact.html" target="_blank"
                           rel="noopener noreferrer"><FormattedMessage id="form_info_contact_link"/></a>

                        <p><FormattedMessage id="form_info_details"/> <a href={mailLink}><FormattedMessage
                            id="form_info_mail"/></a></p>
                    </Alert>

          <GeneralsConditionsContainer>
            <GeneralsConditionsForm
              generalsConditionsUrl={generalsConditionsUrl}
              internalRegulationsUrl={internalRegulationsUrl}
            />
          </GeneralsConditionsContainer>

          <StyledText>
            <FormattedMessage id="info_mail_will_be_sent" />
          </StyledText>

          {isSubmitted && !isValid && (
              <Alert severity="error" variant="outlined" sx={{ marginBottom: "16px" }}>
                <AlertTitle>
                  <FormattedMessage id="form_invalid_alert_title" />
                </AlertTitle>

                <FormattedMessage id="form_invalid_alert" />
              </Alert>
          )}

          <StyledLoadingButton
            fullWidth
            variant="contained"
            size="small"
            type="submit"
            loading={loading}
          >
            <FormattedMessage id="register" />
          </StyledLoadingButton>

          <PersonalDataConditions
            rgpdUrl={rgpdUrl}
            copyrightUrl={copyrightUrl}
          />
        </Box>
      </Container>
    </FormProvider>
  );
}

const Container = styled.div`
  padding-bottom: 32px;
  padding: 0 16px;
  max-width: 620px;
  margin: auto;
`;

// fix form overlapping due to the grid style
const Block = styled.div`
  div {
    margin-top: 0;
  }
`;

const GeneralsConditionsContainer = styled.div`
  margin: 16px 0;
`;

const StyledTitle = styled(Typography)`
  margin-top: 32px;
  color: #575757;
`;

const StyledText = styled(Typography)`
  margin: 24px 0;
  color: #6c6c6c;
`;

const StyledLoadingButton = styled(LoadingButton)`
  margin-top: 16px;
  height: 40px;
`;

export default Registration;
