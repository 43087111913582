import * as React from "react";
import { FormattedMessage } from "react-intl";

import styled from "@emotion/styled";

import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import error from "../assets/images/exclamation.png";

function ErrorModal({ isOpen, onClose }) {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Container>
        <Card sx={{ minWidth: 275, maxWidth: 350 }}>
          <CardContent>
            <Image src={error} alt="error"></Image>

            <StyledTypography component="h6" variant="h6" align="center">
              <FormattedMessage
                id="registration_error"
                values={{
                  br: <br />,
                }}
              />
            </StyledTypography>
          </CardContent>

          <StyledCardActions>
            <Button onClick={onClose}>
              <FormattedMessage id="registration_error_close" />
            </Button>
          </StyledCardActions>
        </Card>
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;

  display: flex;
  justify-content: center;
`;

const Image = styled.img`
  padding: 24px;
  width: 100px;
  height: 100px;
  display: block;
  margin: auto;
`;

const StyledTypography = styled(Typography)`
  margin-top: 16px;
  color: #666;
`;

const StyledCardActions = styled(CardActions)`
  float: right;
`;

export default ErrorModal;
