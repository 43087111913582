import { useEffect, useCallback } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import AddressForm from "./AddressForm";

// NOTE: array field is used only for extentions purpose,
// here only element create in that array. ex: that why hadAtLeastOne are not used
function AddressFormArray({ namePrefix }) {
  const { control } = useFormContext();

  const { fields, remove, append } = useFieldArray({
    control,
    name: namePrefix,
    shouldUnregister: true,
  });

  const onAppend = useCallback(() => {
    append({ country: "", street: "", number: "", city: "", postalCode: "" });
  }, [append]);

  // add only one element even if it's render more than once
  useEffect(() => {
    remove();
    onAppend();
  }, [remove, onAppend]);

  return (
    <>
      {fields.map((field, index) => (
        <AddressForm key={field.id} namePrefix={`${namePrefix}[${index}].`} />
      ))}
    </>
  );
}

export default AddressFormArray;
