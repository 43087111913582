import {FormattedMessage, useIntl} from "react-intl";
import {Controller} from "react-hook-form";
import {useEffect, useState} from "react";

import styled from "@emotion/styled";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import ScheduleFormArray from "./ScheduleFormArray";

import {getCourses, getDisciplines, getSchedules,} from "../services/courses-api";
import AlertTitle from "@mui/material/AlertTitle";
import Alert from "@mui/material/Alert";

function CourseForm({namePrefix, selectedCoursesIds, onCourseIdchange}) {
    const intl = useIntl();

    const [selectedDiscipline, setSelectedDiscipline] = useState();
    const [selectedCourse, setSelectedCourse] = useState();

    const [disciplines, setDisciplines] = useState([])
    const [courses, setCourses] = useState([]);
    const [schedules, setSchedules] = useState([]);
    const [loading, setLoading] = useState(false);
    const [uniteCourse, setUniteCourse] = useState(false);

    useEffect(() => {
        fetchDisciplines();
    }, []);

    const fetchSchedules = (courseId) => {
        setLoading(true);

        getSchedules(courseId)
            .then((data) => {
                setSchedules(setHiddenSchedules(data));
            })
            .finally(() => setLoading(false));
    };

    const setHiddenSchedules = (schedules) => {
        let toConfirm = false;
        let onDemand = false;

        setUniteCourse(false);

        let updatedSchedules;
        updatedSchedules = schedules.map(schedule => {
            if (schedule.hide) return {...schedule};
            if (schedule.statusDto === "ON_DEMAND") {
                if (onDemand) {
                    return {...schedule, hide: true};
                } else {
                    onDemand = true;
                    return {...schedule, hide: false};
                }
            }
            if (schedule.statusDto === "TO_CONFIRM") {
                if (toConfirm) {
                    return {...schedule, hide: true};
                } else {
                    toConfirm = true;
                    return {...schedule, hide: false};
                }
            }
            if (schedule.statusDto === "UNITE_COURSE") {
                setUniteCourse(true);
                if (onDemand) {
                    return {...schedule, hide: true};
                } else {
                    onDemand = true;
                    return {...schedule, hide: false};
                }
            }
            return {...schedule, hide: false};
        });

        return updatedSchedules;
    }

    const fetchCourses = (disciplineId) => {
        setLoading(true);

        getCourses(disciplineId)
            .then((data) => {
                setCourses(data);
            })
            .finally(() => setLoading(false));
    };

    const fetchDisciplines = () => {
        setLoading(true);

        getDisciplines()
            .then((data) => {
                setDisciplines(data);
            })
            .finally(() => setLoading(false));
    };

    const hasDisciplines = () => {
        return disciplines?.length;
    };

    const hasCourses = () => {
        return courses?.length;
    };

    const name = (name) => {
        return namePrefix ? namePrefix + name : name;
    };

    const minOccurrence = () => {
        return selectedCourse?.minOccurrence || 0;
    };

    const handleDiscipline = (event) => {
        if (event?.target?.value !== undefined) {
            let discipline_id = event.target.value;
            let discipline = (disciplines || []).find((discipline) => discipline.id === discipline_id);

            setSelectedDiscipline(discipline);

            fetchCourses(discipline.id);
        }
    };

    const handleCourse = (event) => {
        if (event?.target?.value !== undefined) {
            let course_id = event.target.value;
            let course = (courses || []).find((course) => course.id === course_id);

            setSelectedCourse(course);

            fetchSchedules(course.id);
        }
    };

    const isCourseSelected = (id) => {
        return !!(selectedCoursesIds || []).includes(id);
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Controller
                        name={name("disciplineId")}
                        rules={{required: true}}
                        render={({field, fieldState}) => (
                            <TextField
                                {...field}
                                disabled={!hasDisciplines()}
                                required
                                select
                                fullWidth
                                defaultValue=""
                                variant="outlined"
                                size="small"
                                error={fieldState.invalid}
                                onChange={(event) => {
                                    handleDiscipline(event);
                                    field.onChange(event);
                                }}
                                label={intl.formatMessage({id: "discipline_disciplines"})}
                            >
                                {(disciplines || []).map((discipline) => (
                                    <MenuItem
                                        key={discipline.id}
                                        value={discipline.id}
                                        //disabled={isDisciplineSelected(discipline.id)}
                                    >
                                        {discipline.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                </Grid>


                <Grid item xs={6}>
                    <Controller
                        name={name("courseId")}
                        rules={{required: true}}
                        render={({field, fieldState}) => (
                            <TextField
                                {...field}
                                disabled={!hasCourses()}
                                required
                                select
                                fullWidth
                                defaultValue=""
                                variant="outlined"
                                size="small"
                                error={fieldState.invalid}
                                onChange={(event) => {
                                    handleCourse(event);
                                    field.onChange(event);
                                    onCourseIdchange();
                                }}
                                label={intl.formatMessage({id: "discipline_courses"})}
                            >
                                {(courses || []).map((course) => (
                                    <MenuItem
                                        key={course.id}
                                        value={course.id}
                                        disabled={isCourseSelected(course.id)}
                                    >
                                        {course.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                </Grid>

                <StyledGrid item xs={12}>
                    <ScheduleFormArray
                        namePrefix={name("scheduleIds")}
                        minOccurrence={minOccurrence()}
                        schedules={schedules}
                    />
                </StyledGrid>

                {uniteCourse &&(
                    <>
                        <Grid item xs={12} sx={{ margin: "16px 0" }}>
                        </Grid>

                        <StyledGrid item xs={12}>
                            <Alert severity="info" variant="outlined" sx={{marginBottom: "16px"}}>
                                <AlertTitle>
                                    <FormattedMessage id="form_info_yoga_title"/>
                                </AlertTitle>
                                <FormattedMessage id="form_info_yoga"/>
                                <a href="https://www.sophiegobert.com/cours-deacuteteacute.html" target="_blank"
                                   rel="noopener noreferrer"><FormattedMessage id="form_info_yoga_link"/></a>
                            </Alert>
                        </StyledGrid>
                    </>
                )}

            </Grid>
        </>
    );
}

const StyledGrid = styled(Grid)`
    padding-top: 0 !important;
`;

export default CourseForm;
