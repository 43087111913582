import { useIntl } from "react-intl";
import { Controller } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import styled from "@emotion/styled";

import get from "lodash/get";

import ConnectForm from "./ConnectForm";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

function GeneralsConditionsForm({
  namePrefix,
  generalsConditionsUrl,
  internalRegulationsUrl,
}) {
  const name = (name) => {
    return namePrefix ? namePrefix + name : name;
  };

  const intl = useIntl();

  const hasError = (errors, name) => {
    return !!get(errors, (namePrefix || "") + name);
  };

  return (
    <ConnectForm>
      {({ register, formState: { errors } }) => (
        <>
          <FormControl
            required
            error={hasError(errors, "generalsConditions")}
            component="fieldset"
            variant="standard"
          >
            <StyledFormLabel component="legend">
              <FormattedMessage id="generals_conditions_title" />
            </StyledFormLabel>

            <StyledFormGroup>
              <StyledFormControlLabel
                control={
                  <Controller
                    {...register(name("generalsConditions"), {
                      required: true,
                    })}
                    render={({ field }) => <Checkbox {...field} />}
                  />
                }
                label={intl.formatMessage(
                  { id: "general_conditions_text" },
                  {
                    generals_conditions_url: (
                      <a href={generalsConditionsUrl}>
                        <FormattedMessage id="generals_conditions" />
                      </a>
                    ),
                    internal_regulations_url: (
                      <a href={internalRegulationsUrl}>
                        <FormattedMessage id="internal_regulations" />
                      </a>
                    ),
                  }
                )}
              />
            </StyledFormGroup>
          </FormControl>
        </>
      )}
    </ConnectForm>
  );
}

const StyledFormLabel = styled(FormLabel)``;

const StyledFormGroup = styled(FormGroup)`
  margin-top: 8px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  color: #575757;
`;

export default GeneralsConditionsForm;
