import React from "react";

import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { Controller } from "react-hook-form";

import ConnectForm from "./ConnectForm";

function PhoneInput({ namePrefix }) {
  // FIXME put in a service
  const name = (name) => {
    return namePrefix ? namePrefix + name : name;
  };

  return (
    <ConnectForm>
      {({ control }) => (
        <Controller
          name={name("phone")}
          control={control}
          rules={{ validate: matchIsValidTel, required: true }}
          render={({ field, fieldState }) => (
            <MuiTelInput
              {...field}
              required
              fullWidth
              id={name("phone")}
              variant="outlined"
              size="small"
              defaultCountry="BE"
              onlyCountries={["BE", "FR", "LU"]}
              preferredCountries={["BE", "FR", "LU"]}
              error={fieldState.invalid}
            />
          )}
        />
      )}
    </ConnectForm>
  );
}

export default PhoneInput;
