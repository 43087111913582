import {useIntl} from "react-intl";
import {Controller, useFormContext} from "react-hook-form";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

function ScheduleForm({namePrefix, schedules, selectedSchedulesIds, onScheduleIdchange}) {
    const {control} = useFormContext();

    const intl = useIntl();

    const name = (name) => {
        return namePrefix ? namePrefix + name : name;
    };

    const hasSchedules = () => {
        return schedules?.length;
    };

    const getScheduleLabel = (schedule) => {
        if (isScheduleNotValid(schedule)) {
            if (schedule.statusDto === "ON_DEMAND" || schedule.statusDto === "UNITE_COURSE") return intl.formatMessage({id: "on_demand"});
            else {
                //schedule.statusDto = "TO_CONFIRM";
                return intl.formatMessage({id: "to_confirm"});
            }
        }
        let label = schedule.day + " de " + schedule.start.substring(0, 5) + " à " + schedule.end.substring(0, 5);
        if (schedule.statusDto == "FULL"){
            label += " (COMPLET)";
        }
        return label;
    };

    const isScheduleAlreadySelected = (id) => {
        return !!(selectedSchedulesIds || []).includes(id);
    };

    const isScheduleNotValid = (schedule) => {
        return schedule.day == null ||
            schedule.start == null ||
            schedule.end == null ||
            schedule.statusDto === "ON_DEMAND" ||
            schedule.statusDto === "TO_CONFIRM" ||
            schedule.statusDto === "UNITE_COURSE";
    }

    const isScheduleDisabled = (schedule) => {
        if (isScheduleNotValid(schedule)) return true;
        return !!(selectedSchedulesIds || []).includes(schedule.id);
    };

    const validateScheduleId = (value) => {
        const isValid = schedules.some((schedule) => schedule.id === value);
        return isValid || intl.formatMessage({id: "error_required_schedule"});
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name={name("")}
                        rules={{
                            required: true,
                            validate: {
                                validScheduleId: validateScheduleId
                            }
                        }}
                        render={({field, fieldState}) => (
                            <TextField
                                {...field}
                                disabled={!hasSchedules()}
                                required
                                select
                                fullWidth
                                variant="outlined"
                                size="small"
                                error={fieldState.invalid}
                                label={intl.formatMessage({id: "discipline_schedule"})}
                                onChange={(event) => {
                                    field.onChange(event);
                                    onScheduleIdchange();
                                }}
                            >
                                {(schedules || []).filter(schedule => !schedule.hide).map((schedule) => (
                                    <MenuItem
                                        key={schedule.id}
                                        value={schedule.id}
                                        disabled={isScheduleDisabled(schedule)}
                                    >
                                        {getScheduleLabel(schedule)}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default ScheduleForm;
