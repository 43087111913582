import { FormattedMessage } from "react-intl";

import styled from "@emotion/styled";

import Typography from "@mui/material/Typography";

import success from "../assets/images/correct.png";

function Success() {
    return (
        <Container>
            <div>
                <Image src={success} alt="success"></Image>

                <StyledTypography component="h1" variant="h4" align="center">
                    <FormattedMessage id="registration_success"/>
                </StyledTypography>

                <RedTypography component="h2" variant="h6" align="center">
                    <FormattedMessage id="important"/>
                </RedTypography>

                <RedTypography component="h2" variant="h6" align="center">
                    <FormattedMessage id="check_junk"/>
                </RedTypography>

                <RedTypography component="h2" variant="h6" align="center">
                    <FormattedMessage id="no_mail_contact_us"/>
                </RedTypography>
            </div>
        </Container>
    );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
`;

const Image = styled.img`
  padding: 24px;
  width: 100px;
  height: 100px;
  display: block;
  margin: auto;
`;

const StyledTypography = styled(Typography)`
  margin-top: 16px;
  color: #666;
`;

const RedTypography = styled(Typography)`
  margin-top: 16px;
  color: red;
`;

export default Success;
