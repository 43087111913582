import React from "react";
import reportWebVitals from "./reportWebVitals";

import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";

import { Global, css } from "@emotion/react";

import { IntlProvider } from "react-intl";
import { fr } from "./intl/fr";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { frFR } from "@mui/x-date-pickers/locales";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";

import Registration from "./pages/Registration";
import Success from "./pages/Success";
import Error from "./pages/Error";
import NotAvailable from "./pages/NotAvailable";

dayjs.extend(customParseFormat);
dayjs.extend(utc);

const GlobalStyles = css`
  body {
    padding: 0 !important;
    margin: 0 !important;
  }

  a:focus,
  button:focus,
  input:focus,
  :focus {
    outline: none;
  }

  a::-moz-focus-inner,
  button::-moz-focus-inner,
  input::-moz-focus-inner,
  ::-moz-focus-inner {
    border: 0;
  }

  .MuiTextField-root {
    background: white;
  }
`;

const router = createBrowserRouter([
  {
    path: "*",
    element: <Navigate to="/registration" />,
  },
  {
    path: "registration",
    element: <Registration />,
  },
  {
    path: "success",
    element: <Success />,
  },
  {
    path: "error",
    element: <Error />,
  },
  {
    path: "not-available",
    element: <NotAvailable />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Global styles={GlobalStyles} />
    <IntlProvider messages={fr} locale="fr" defaultLocale="fr">
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        localeText={
          frFR.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <RouterProvider router={router} />
      </LocalizationProvider>
    </IntlProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
