import {FormattedMessage, useIntl} from "react-intl";

import styled from "@emotion/styled";

import Typography from "@mui/material/Typography";

import error from "../assets/images/error.png";

function Error() {
    const { formatMessage } = useIntl();
    const infoEmail = formatMessage({ id: 'form_info_mail' });
    const mailLink = `mailto:${infoEmail}`;
    return (
        <Container>
            <div>
                <Image src={error} alt="success"></Image>

                <StyledTypography component="h1" variant="h4" align="center">
                    <FormattedMessage id="error_title"/>
                </StyledTypography>

                <RedTypography component="h2" variant="h6" align="center">
                    <FormattedMessage id="error_text_1"/>
                    <a href="https://www.sophiegobert.com/contact.html" target="_blank"
                       rel="noopener noreferrer"><FormattedMessage id="form_info_contact_link"/></a>
                </RedTypography>

                <RedTypography component="h2" variant="h6" align="center">
                    <FormattedMessage id="error_text_2"/> <a href={mailLink}><FormattedMessage
                    id="form_info_mail"/></a>
                </RedTypography>
            </div>
        </Container>
    );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
`;

const Image = styled.img`
  padding: 24px;
  width: 100px;
  height: 100px;
  display: block;
  margin: auto;
`;

const StyledTypography = styled(Typography)`
  margin-top: 16px;
  color: #666;
`;

const RedTypography = styled(Typography)`
  margin-top: 16px;
  color: red;
`;

export default Error;
